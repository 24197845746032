<template>
  <v-footer class="gradient text-center">
    <v-row justify="center" no-gutters
      ><v-btn
        v-for="item in menuItems"
        :key="item.name"
        variant="text"
        :to="item.url"
        class="ma-2 text-indigo-accent-4"
        >{{ item.name }}</v-btn
      >
      <v-col class="text-center mt-4" cols="12">
        © {{ new Date().getFullYear() }} panman Inc.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import constants from "@/common/constants";
export default {
  data: () => ({
    menuItems: constants.menuItems,
  }),
};
</script>

<style>
.gradient {
  background: linear-gradient(to right, #f5f5f5, 70%, #a9a9a9) !important;
}
</style>